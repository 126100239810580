<template>
  <div>
    <portal to="assetsFilter">
      <div class="pl-3 asset-filter__button-wrapper">
        <button class="ab-button--white" @click="toggleFilters">Filters</button>
      </div>
      <div :class="filterContainerClasses">
        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('app.category') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <span
                v-for="(category, index) in categoryItems"
                :key="index"
                :class="{ 'is-active': index === activeCategory }"
                @click="categoryAssetFilter(category, index)"
              >
                {{ category.name }}
              </span>
            </div>
          </template>
        </dropdown-item>

        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('assets.theme_or_campaign') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <span
                v-for="(campaign, index) in campaignItems"
                :key="index"
                :class="{ 'is-active': index === activeCampaign }"
                @click="campaignAssetFilter(campaign, index)"
              >
                {{ campaign.name }}
              </span>
            </div>
          </template>
        </dropdown-item>

        <!-- START CHANNEL DROPDOWN -->
        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('assets.channel') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <span
                v-for="(channel, index) in channelItems"
                :key="index"
                :class="{ 'is-active': index === activeChannel }"
                @click="channelAssetFilter(channel, index)"
              >
                {{ channel.name }}
              </span>
            </div>
          </template>
        </dropdown-item>
        <!-- STOP CHANNEL DROPDOWN -->

        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('assets.uploaded_at') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <div class="range-picker">
                <v-date-picker
                  v-model="uploaded_at"
                  mode="range"
                  is-inline
                  is-light
                />
              </div>
            </div>
          </template>
        </dropdown-item>

        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('assets.communication_date') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <div class="range-picker">
                <v-date-picker
                  v-model="communicated_from_to"
                  mode="range"
                  is-inline
                  is-light
                />
              </div>
            </div>
          </template>
        </dropdown-item>
        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('app.uploader') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <span
                v-for="(uploader, index) in uploaders"
                :key="index"
                class="asset-filter__uploader"
                :class="{ 'is-active': index === activeUploader }"
                @click="uploaderAssetFilter(uploader, index)"
              >
                <div class="grid-x align-middle">
                  <div class="cell shrink">
                    <div
                      class="user-avatar"
                      :style="userAvatar(uploader.avatar.url)"
                    ></div>
                  </div>
                  <div class="cell auto">
                    {{ uploader.name }}
                  </div>
                </div>
              </span>
            </div>
          </template>
        </dropdown-item>

        <dropdown-item
          ref="dropdown"
          :class-name="'asset-filter'"
          :class="'asset-filter'"
          :isIcon="false"
          :align="'bottom'"
          :width="400"
          :y="0"
          :x="0"
        >
          <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('app.language') }}
            </span>
          </template>
          <template slot="body">
            <div class="asset-filter__dropdown-content">
              <span
                v-for="(language, index) in languageItems"
                :key="index"
                :class="{ 'is-active': language.code === activeLanguage }"
                @click="languageAssetFilter(language, language.code)"
              >
                {{ language.name }}
              </span>
            </div>
          </template>
        </dropdown-item>
      </div>
    </portal>
    <portal to="pageHeaderLeft">
      <div class="asset-search" v-if="autocompleteItems">
        <vue-tags-input
          v-model="tag"
          :tags="tags"
          :placeholder="$t('app.filter')"
          :autocomplete-items="filteredItems"
          @tags-changed="update"
        >
          <div
            slot="tag-center"
            slot-scope="props"
            :class="'tag-type--' + props.tag.class"
          >
            <div class="tag-user" v-if="props.tag.type == 'uploader'">
              <div
                class="tag-user__avatar"
                :style="userAvatar(props.tag.avatar.url)"
              ></div>
              {{ props.tag.name }}
            </div>
            <span v-else>
              {{ props.tag.name }}
            </span>
            <i class="icon-close" @click="props.performDelete(props.index)"></i>
          </div>
        </vue-tags-input>
      </div>
    </portal>
    <portal to="pageHeaderRight">
      <contact-dropdown/>
      <router-button
        v-if="$store.state.is_brand_manager"
        icon="icon-plus"
        routeName="AssetCreate"
        :routeParam="$route.path"
        cssClass="ab-button--white ab-button--create"
        :text="$t('assets.create_asset')"
      />
    </portal>

    <div class="spacer-top"></div>

    <div class="grid-x grid-margin-x">
      <div class="cell medium-12 page__inner-content">
        <div class="grid-x grid-margin-x grid card-list">
          <router-link
            v-for="(asset, index) in assets"
            :key="index"
            :to="{ name: 'AssetDetail', params: { pathName: asset.slug } }"
            class="cell grid__item medium-6 xlarge-3 card"
          >
            <card-item :no-content-wrapper="true">
              <div class="card__title">{{ asset.title }}</div>
              <figure class="card__image">
                <ul class="card__tags">
                  <li>{{ asset.category.name }}</li>
                </ul>
                <v-lazy-image
                  v-if="asset.preview_image"
                  :src="asset.preview_image.url"
                />
              </figure>
            </card-item>
          </router-link>
        </div>
        <intersection-observer @intersect="intersected"/>
      </div>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import VueTagsInput from '@johmun/vue-tags-input'
import VLazyImage from 'v-lazy-image'
import IntersectionObserver from '@/views/common/components/elements/IntersectionObserver'
import moment from 'moment'

export default {
  name: 'AssetList',
  data () {
    return {
      search: '',
      tag: '',
      tags: [],
      autocompleteItems: [],
      categoryItems: [],
      campaignItems: [],
      channelItems: [],
      languageItems: [],
      uploaders: [],
      page: 1,
      assets: [],
      showFilters: false,
      id: null,
      uploaded_at: {
        start: null,
        end: null
      },
      communicated_from_to: {
        start: null,
        end: null
      },
      activeCategory: null,
      activeUploader: null,
      activeLanguage: null,
      activeCampaign: null,
      activeChannel: null,
      loading: false
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/tags`).then((response) => {
      const autoCompleteTags = []
      for (const key in response.data) {
        const tag = response.data[key]
        if (tag.name !== '') {
          const tagData = {
            name: tag.name,
            type: 'tag',
            text: tag.name,
            class: 'tag'
          }
          autoCompleteTags.push(tagData)
        }
      }
      this.autocompleteItems = autoCompleteTags.sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
    })

    backend
      .get(`${process.env.VUE_APP_URL}/assets/categories`)
      .then((response) => {
        for (const key in response.data) {
          const category = response.data[key]
          if (category.name !== '') {
            this.categoryItems.push({
              name: category.name,
              type: 'category',
              text: category.name,
              class: 'category'
            })
          }
        }
      })

    backend.get(`${process.env.VUE_APP_URL}/campaigns`).then((response) => {
      for (const key in response.data) {
        const campaign = response.data[key]
        if (campaign.name !== '') {
          this.campaignItems.push({
            name: campaign.name,
            type: 'campaign',
            text: campaign.name,
            class: 'campaign'
          })
        }
      }
    })

    // START CHANNEL BACKEND
    backend.get(`${process.env.VUE_APP_URL}/channels`).then((response) => {
      for (const key in response.data) {
        const channel = response.data[key]
        if (channel.name !== '') {
          this.channelItems.push({
            name: channel.name,
            type: 'channel',
            text: channel.name,
            class: 'channel'
          })
        }
      }
    })
    // STOP CHANNEL BACKEND

    backend.get(`${process.env.VUE_APP_URL}/users`).then((response) => {
      for (const key in response.data) {
        const uploader = response.data[key]
        if (uploader.name !== '') {
          this.uploaders.push({
            name: uploader.name + ' ' + uploader.surname,
            type: 'uploader',
            text: uploader.name + ' ' + uploader.surname,
            id: uploader.id,
            avatar: uploader.avatar,
            class: 'other'
          })
        }
      }
    })

    backend
      .get(`${process.env.VUE_APP_URL}/assets/languages`)
      .then((response) => {
        for (const key in response.data) {
          const language = response.data[key]
          this.languageItems.push({
            name: language.name,
            type: 'category',
            code: language.code,
            class: 'category'
          })
        }
      })
  },
  methods: {
    toggleFilters () {
      this.showFilters = !this.showFilters
    },
    userAvatar (avatar) {
      return 'background-image:url(\'' + avatar + '\');'
    },
    dateFormat (value, format) {
      if (value == null) {
        return moment().format(format)
      }
      return moment(value).format(format)
    },
    async intersected () {
      if (this.loading) {
        return
      }
      this.loading = true
      backend.get(this.assetsUrl).then((response) => {
        this.assets = [...this.assets, ...response.data]
        this.loading = false
      })
    },
    refreshAssets () {
      if (this.loading) {
        return
      }
      this.loading = true
      this.showFilters = false
      this.assets = []
      backend.get(this.assetsUrl).then((response) => {
        this.assets = response.data
        this.loading = false
      })
    },
    update (newTags) {
      newTags.forEach((tag, index) => {
        if (!tag.class) {
          tag.name = tag.text
          tag.type = 'text'
          tag.class = 'other'
        }
      })
      this.tags = newTags
      this.refreshAssets()
    },
    categoryAssetFilter (item, index) {
      this.activeCategory = index
      this.tags.forEach((cat, index) => {
        if (cat.type === 'category') {
          this.tags.splice(index, 1)
        }
      })
      this.tags.push({
        name: item.name,
        type: 'category',
        text: item.name,
        class: 'category'
      })
      this.refreshAssets()
    },
    campaignAssetFilter (item, index) {
      this.activeCampaign = index
      this.tags.forEach((cat, index) => {
        if (cat.type === 'campaign') {
          this.tags.splice(index, 1)
        }
      })
      this.tags.push({
        name: item.name,
        type: 'campaign',
        text: item.name,
        class: 'category'
      })
      this.refreshAssets()
    },

    // CHANNEL FILTER
    channelAssetFilter (item, index) {
      this.activeChannel = index
      this.tags.forEach((cat, index) => {
        if (cat.type === 'channel') {
          this.tags.splice(index, 1)
        }
      })
      this.tags.push({
        name: item.name,
        type: 'channel',
        text: item.name,
        class: 'category'
      })
      this.refreshAssets()
    },

    uploaderAssetFilter (item, index) {
      this.activeUploader = index
      this.tags.forEach((uploader, index) => {
        if (uploader.type === 'uploader') {
          this.tags.splice(index, 1)
        }
      })
      this.tags.push({
        name: item.name,
        type: 'uploader',
        text: item.name,
        avatar: item.avatar,
        id: item.id,
        class: 'other'
      })
      this.refreshAssets()
    },
    languageAssetFilter (item, code) {
      this.activeLanguage = code
      this.tags.forEach((cat, index) => {
        if (cat.type === 'language') {
          this.tags.splice(index, 1)
        }
      })
      this.tags.push({
        name: item.name,
        type: 'language',
        text: item.name,
        code: item.code,
        class: 'other'
      })
      this.refreshAssets()
    }
  },
  watch: {
    tags: function () {
      if (!this.tags.some((tag) => tag.type === 'category')) {
        this.activeCategory = null
      }
      if (!this.tags.some((tag) => tag.type === 'uploader')) {
        this.activeUploader = null
      }
      if (!this.tags.some((tag) => tag.type === 'language')) {
        this.activeLanguage = null
      }
    },
    uploaded_at: function () {
      if (this.uploaded_at) {
        this.tags.forEach((date, index) => {
          if (date.type === 'uploaded_at') {
            this.tags.splice(index, 1)
          }
        })

        this.tags.push({
          name: this.$t('assets.uploaded_between', {
            from: this.dateFormat(this.uploaded_at.start, 'D MMM YYYY'),
            to: this.dateFormat(this.uploaded_at.end, 'D MMM YYYY')
          }),
          type: 'uploaded_at',
          class: 'other',
          text: this.$t('assets.uploaded_between', {
            from: this.dateFormat(this.uploaded_at.start, 'D MMM YYYY'),
            to: this.dateFormat(this.uploaded_at.end, 'D MMM YYYY')
          }),
          date_from: this.dateFormat(this.uploaded_at.start, 'YYYY-MM-DD'),
          date_to: this.dateFormat(this.uploaded_at.end, 'YYYY-MM-DD')
        })
        this.refreshAssets()
      }
    },
    communicated_from_to: function () {
      if (this.communicated_from_to) {
        this.tags.forEach((date, index) => {
          if (date.type === 'communicated_from_to') {
            this.tags.splice(index, 1)
          }
        })

        this.tags.push({
          name: this.$t('assets.communicated_from_to', {
            from: this.dateFormat(
              this.communicated_from_to.start,
              'D MMM YYYY'
            ),
            to: this.dateFormat(this.communicated_from_to.end, 'D MMM YYYY')
          }),
          type: 'communicated_from_to',
          class: 'other',
          text: this.$t('assets.communicated_from_to', {
            from: this.dateFormat(
              this.communicated_from_to.start,
              'D MMM YYYY'
            ),
            to: this.dateFormat(this.communicated_from_to.end, 'D MMM YYYY')
          }),
          communicated_from: this.dateFormat(
            this.communicated_from_to.start,
            'YYYY-MM-DD'
          ),
          communicated_to: this.dateFormat(
            this.communicated_from_to.end,
            'YYYY-MM-DD'
          )
        })
        this.refreshAssets()
      }
    }
  },
  computed: {
    filterContainerClasses () {
      return {
        'asset-filter__container': true,
        'asset-filter__container--open': this.showFilters
      }
    },
    assetsUrl () {
      let url = `${process.env.VUE_APP_URL}/assets?`
      if (this.tags.length > 0) {
        for (const tagIndex in this.tags) {
          if (this.tags[tagIndex].type === 'tag') {
            url =
              url + `&filter[tags][${tagIndex}]=${this.tags[tagIndex].name}`
          }
          if (this.tags[tagIndex].type === 'category') {
            url = url + `&filter[category]=${this.tags[tagIndex].name}`
          }
          if (this.tags[tagIndex].type === 'campaign') {
            url = url + `&filter[campaign]=${this.tags[tagIndex].name}`
          }
          if (this.tags[tagIndex].type === 'channel') {
            url = url + `&filter[channel]=${this.tags[tagIndex].name}`
          }
          if (this.tags[tagIndex].type === 'uploaded_at') {
            url = url + `&filter[date_from]=${this.tags[tagIndex].date_from}`
            url = url + `&filter[date_to]=${this.tags[tagIndex].date_to}`
          }
          if (this.tags[tagIndex].type === 'uploader') {
            url = url + `&filter[uploaded_by]=${this.tags[tagIndex].id}`
          }
          if (this.tags[tagIndex].type === 'communicated_from_to') {
            url =
              url +
              `&filter[communicated_from]=${this.tags[tagIndex].communicated_from}`
            url =
              url +
              `&filter[communicated_to]=${this.tags[tagIndex].communicated_to}`
          }
          if (this.tags[tagIndex].type === 'language') {
            url = url + `&filter[language]=${this.tags[tagIndex].code}`
          }
          if (this.tags[tagIndex].type === 'text') {
            url = url + `&filter[text]=${this.tags[tagIndex].name}`
          }
        }
      }
      url = url + `&offset=${this.assets.length}&limit=40`
      return url
    },
    filteredItems () {
      if (this.tag.length < 1) return
      return this.autocompleteItems.filter((i) => {
        return i.name.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    }
  },
  components: {
    VueTagsInput,
    VLazyImage,
    IntersectionObserver
  }
}
</script>

<style lang="scss" scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
